import { graphql } from "gatsby";
import {
  AgeRange,
  MicrobitCode,
  ProgrammingLanguage,
  SanityAsset,
  SanityCodeDocument,
  SanityImage,
  SanityLicence,
  SanityPage,
  SanityRawContent,
  SanitySkill,
  SanitySocialDocument,
  SanityExternalCollaborator,
  SanitySubject,
  SanityTopic,
  SanityTranslatedDocument
} from "./common";
import { SanityMake } from "./makes";
import { SanityPdCourse } from "./teach";

export const unitOfWorkTopicsFragment = graphql`
  fragment UnitOfWorkTopics on SanityUnitOfWork {
    topics {
      _id
      name
      subject {
        _id
        name
        computingRelated
      }
    }
  }
`;

export const lessonTopicsFragment = graphql`
  fragment LessonTopics on SanityLesson {
    topics {
      _id
      name
      subject {
        _id
        name
        computingRelated
      }
    }
  }
`;

export const makeTopicsFragment = graphql`
  fragment MakeTopics on SanityMake {
    topics {
      _id
      name
      subject {
        _id
        name
        computingRelated
      }
    }
  }
`;

export type DownloadCategory = "plan" | "slides" | "student-handouts" | "other";

export type UnitType = "designChallenge" | "unitOfWork";

export const fragmentUnitOfWorkForListing = graphql`
  fragment SanityUnitOfWorkForListing on SanityUnitOfWork {
    _id
    _type
    unitType
    slug {
      current
    }
    title
    description
    _rawLearningObjectives
    image {
      ...SanityImage
    }
    contents {
      _id
      _type
      title
      slug {
        current
      }
      ...LessonTopics
    }
    ageRange
    ...UnitOfWorkTopics
    programmingLanguages
    _rawIntroduction(resolveReferences: { maxDepth: 5 })
    sponsor {
      ...ExternalCollaborator
    }
  }
`;

/**
 * Common interface to a unit of work and a design challenge.
 * There are currently no specific fields so we just use this type.
 */
export interface SanityUnitOfWork
  extends SanityTranslatedDocument,
    SanitySocialDocument {
  _rawCurriculumLinks: SanityRawContent;
  _rawIntroduction: SanityRawContent;
  _rawLearningObjectives: SanityRawContent;
  additionalSkills: SanitySkill[];
  contents: SanityLesson[];
  description: string;
  programmingLanguages: ProgrammingLanguage[];
  slug: { current: string };
  _rawSideBarText: SanityRawContent;
  image: SanityImage;
  title: string;
  /**
   * Only old units have topics at the unit level. For others they must be aggregated from lessons.
   * Over time we'll push them down then drop this field.
   * See units-util for helper.
   */
  topics: SanityTopic[];
  related: Array<SanityUnitOfWork | SanityPdCourse | SanityMake>;
  sponsor?: SanityExternalCollaborator;
  unitType: UnitType;
  ageRange: AgeRange[];
  licence?: SanityLicence;
}

export const subjectsFromUnit = (
  unitOfWork: SanityUnitOfWork
): SanitySubject[] => subjectsFromTopics(unitOfWork.topics);

export const subjectsFromTopics = (topics: SanityTopic[]): SanitySubject[] => {
  const subjects: Record<string, SanitySubject> = {};
  topics.forEach(t => (subjects[t.subject._id] = t.subject));
  return Object.values(subjects);
};

export interface SanityDownload {
  _id: string;
  _key: string;
  name: string;
  category: DownloadCategory;
  file: {
    asset: SanityAsset;
  };
  pdf: {
    asset: SanityAsset;
  };
}

/**
 * This is used for a lesson in a unit of work and a design activity in a design challenge.
 */
export interface SanityLesson
  extends SanityTranslatedDocument,
    SanitySocialDocument,
    SanityCodeDocument {
  title: string;
  slug: { current: string };
  _rawIntroduction: SanityRawContent;
  _rawLearningObjectives: SanityRawContent;
  /**
   * Plan still used for HEX file generation.
   */
  _rawPlan: SanityRawContent;
  plan: { _type: string }[];
  downloads: SanityDownload[];
  image?: SanityImage;
  topics: SanityTopic[];
  completedCode?: MicrobitCode;
  code: MicrobitCode[];
}

export interface SanityLessonsPage extends SanityPage {
  _rawSideBarText?: SanityRawContent[];
}

interface TextIds {
  lesson: string;
  singular: string;
  related: string;
}

export const textIdsForUnit = (unit: SanityUnitOfWork): TextIds => {
  const type = unit.unitType;
  switch (type) {
    case "designChallenge": {
      return {
        lesson: "activity",
        singular: "design-challenge",
        related: "related-design-challenges"
      };
    }
    case "unitOfWork": {
      return {
        lesson: "lesson",
        singular: "unit-of-work",
        related: "related-units-of-work"
      };
    }
    default:
      throw new Error(`Unexpected value ${type}`);
  }
};
