import React from "react";
import { useLanguage } from "../../i18n/LanguageContext";
import { ContentTranslationSponsor, SanityLicence } from "../../model/common";
import BlockContent from "../BlockContent/BlockContent";
import ExternalCollaboratorCredit from "../ExternalCollaboratorCredit/ExternalCollaboratorCredit";
import styles from "./Credits.module.scss";

interface CreditsProps {
  licence?: SanityLicence;
  translationSponsors?: ContentTranslationSponsor[];
}

/**
 * Block content with license and sponsorship details.
 *
 * Suitable for the main content card in a page.
 */
const Credits = ({ licence, translationSponsors }: CreditsProps) => {
  const language = useLanguage();
  const sponsor = translationSponsors?.find(
    s => s.languages.indexOf(language) !== -1
  )?.sponsor;
  return (
    <>
      {(licence || sponsor) && (
        <div className={styles.licenceSponsor}>
          {licence && (
            <BlockContent
              bodyVariant="default"
              className={styles.credit}
              content={licence._rawText}
            />
          )}
          {sponsor && (
            <ExternalCollaboratorCredit
              size="lg"
              className={styles.translation}
              sponsor={sponsor}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Credits;
